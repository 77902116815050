import './reset.css';

import App from './app';
import React from 'react';
import { i18n } from 'services/lang';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider as Provider } from 'react-i18next';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon]);
library.add(...iconList);

render(
    <Provider i18n={i18n}>
        <App/>
    </Provider>,
    document.getElementById('app-lp')
);

serviceWorker.register();
