/** @name Images */
import ImgAoVivo from 'images/toolsfullcam/ao_vivo.png'
import ImgChatGrupos from 'images/toolsfullcam/chat_grupos.png';
import ImgSelecaoDia from 'images/toolsfullcam/selecao_dia.png';
import ImgServidorAWS from 'images/toolsfullcam/servidor_aws.png';
import ImgDownloadVideos from 'images/toolsfullcam/downloads_videos.png';
import ImgInteligenciaArtificial from 'images/toolsfullcam/inteligencia_artificial.png';
import ImgMonitoramentoTempoReal from 'images/toolsfullcam/monitoramento_tempo_real.png';
import ImgLPR from 'images/toolsfullcam/lpr.png';
import ImgFacial from "images/toolsfullcam/facial.png"
import ImgAlarme from "images/toolsfullcam/alarme.png"
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/** @name Internal */
import { ItemFuncionalities } from './itemFuncionalities'
/**
 * @author Hector Rodrigues Da Silva
 */
export const ToolsFullCam = ({ t }) =>
    <div data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <section className="info__options__fullcam">
            <article className="content__options__fullcam">
                <h1>{t('com_fullcam_ainda_possivel')}</h1>
                <ul>
                    <ItemFuncionalities
                        iconImg={ImgAoVivo}
                        text={t('opcao_aovivo')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgChatGrupos}
                        text={t('opcao_chat_grupos_cameras')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgMonitoramentoTempoReal}
                        text={t('opcao_monitoramento_tempo_real')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgDownloadVideos}
                        text={t('opcao_download_compartilhamento_videos')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgSelecaoDia}
                        text={t('opcao_selecao_dia_horario_video')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgInteligenciaArtificial}
                        text={t('opcao_inteligencia_artificial')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgLPR}
                        text={t('opcao_lpr')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgServidorAWS}
                        text={t('opcao_servidor_aws')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgFacial}
                        text={t('opcao_facial')}
                    />
                    <ItemFuncionalities
                        iconImg={ImgAlarme}
                        text={t('opcao_alarme')}
                    />
                </ul>
            </article>
        </section>
    </div>;

ToolsFullCam.propTypes = {
    t: PropTypes.func
};