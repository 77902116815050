/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemSolution = ({ img, title, children }) =>
    <article className="item__ilustration">
        <img
            src={img}
            alt={title}
            width="auto"
            height={200}
        />
        <h1>{title}</h1>
        <p>{children}</p>
    </article>

ItemSolution.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.elementType
};