/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/** @name Internal */
import { CallCenter } from './callCenter';
import { FormContact } from './formContact';
/**
 * @author Hector Rodrigues Da Silva
 */
export const KnowMore = ({ t, isBrasil, submitContact, onChangeInputForm, onChangeCNPJ_Brasil, stateContact, stateIsLoad }) =>
    <div className="more__informations__fullcam" style={{ backgroundColor: 'white' }}>
        <CallCenter
            t={t}
            isBrasil={isBrasil}
        />
        <FormContact
            t={t}
            isBrasil={isBrasil}
            isLoad={stateIsLoad}
            values={stateContact}
            onSubmitContact={submitContact}
            onChangeValue={onChangeInputForm}
            onChangeCNPJ_Brasil={onChangeCNPJ_Brasil}
        />
    </div>;

KnowMore.propTypes = {
    t: PropTypes.func,
    isBrasil: PropTypes.bool,
    submitContact: PropTypes.func,
    onChangeInputForm: PropTypes.func,
    onChangeCNPJ_Brasil: PropTypes.func,
    stateContact: PropTypes.object,
    stateIsLoad: PropTypes.bool
};