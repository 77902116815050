/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * @author Hector Rodrigues Da Silva
 */
export const FormContact = ({ isBrasil, onChangeCNPJ_Brasil, onSubmitContact, onChangeValue, t, values, isLoad }) => {
    return (
        <div className="container__form__contact">
            <fieldset>
                <form className="form__contact">
                    <div className="title__form__contact">
                        <h1>{t('se_preferir_deixe_seus_dados')}</h1>
                        <h1>{t('entraremos_em_contato')}</h1>
                    </div>
                    <input
                        type="text"
                        id="nome"
                        value={values.nome}
                        onChange={onChangeValue}
                        placeholder={t('nome_completo')}
                        className="name__input__contact"
                    />
                    <div className="input__group__contact">
                        <input
                            id="email"
                            type="email"
                            value={values.email}
                            onChange={onChangeValue}
                            placeholder={t('email')}
                            className="email__input__contact"
                        />
                        <input
                            type="text"
                            id="telefone"
                            value={values.telefone}
                            onChange={onChangeValue}
                            placeholder={t('telefone')}
                            className="telephone__input__contact"
                        />
                    </div>
                    <div className="input__group__contact">
                        <input
                            type="text"
                            id="empresa"
                            value={values.empresa}
                            onChange={onChangeValue}
                            placeholder={t('empresa')}
                            className="company__input__contact"
                        />
                        <input
                            type="text"
                            id="cpf_cnpj"
                            placeholder={t('cnpj')}
                            value={values.cpf_cnpj}
                            className="cnpj__input__contact"
                            onChange={isBrasil ? onChangeCNPJ_Brasil : onChangeValue}
                        />
                    </div>
                    <div className="container__submit__form">
                        <button
                            type="button"
                            onClick={onSubmitContact}
                            className="button__submit__contact">
                            {!isLoad 
                                ? <FontAwesomeIcon icon="paper-plane" /> 
                                : <FontAwesomeIcon className="fa-spinner" icon="spinner" />
                            }
                            &nbsp;{t('enviar')}
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    );
};

FormContact.propTypes = {
    isBrasil: PropTypes.bool,
    onChangeCNPJ_Brasil: PropTypes.func,
    onSubmitContact: PropTypes.func,
    onChangeValue: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
    isLoad: PropTypes.bool
};