/**
 * @author Hector Rodrigues Da Silva
 */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_pt from './app/pt.json';
import common_es from './app/es.json';
import common_en from './app/en.json';

localStorage.removeItem('i18nextLng');

i18next
    .use(LanguageDetector)
    .init({
        resources: {
            pt: {
                common: common_pt
            },
            es: {
                common: common_es
            },
            en: {
                common: common_en
            }
        },
        fallbackLng: 'en',
        interpolation: { escapeValue: false }
    });

export const i18n = i18next;