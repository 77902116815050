/** @name Images */
import ImgGoogle from 'images/footer/google.png';
import ImgStreetView from 'images/footer/streetview_icon.png';
import ImgFulltimeLatam from 'images/footer/fulltime_latam.png';
import ImgFulltimeBrasil from 'images/footer/fulltime_brasil.jpg';
import ImgFulltimeMexico from 'images/footer/fulltime_mexico.png';
import ImgFulltimeArgentina from 'images/footer/fulltime_argentina.png';
/** @name CSS */
import './index.css';
import 'leaflet/dist/leaflet.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Map, Marker, TileLayer, Popup } from 'react-leaflet';
/** @name External */
import { ActionsClient } from "services/actions";
/**
 * @author Hector Rodrigues Da Silva
 */
export const MapFooter = ({ localeLanguage }) => {
    const [size, setSize] = useState(window.innerWidth);
    const sizeMobile = size <= 1580;

    const ImgMarker = new window.L.Icon({
        iconUrl: require('../../../../images/footer/marker.png'),
        iconRetinaUrl: require('../../../../images/footer/marker.png'),
        iconSize: new window.L.Point(50, 62)
    });

    const renderImageCompany = language => {
        const companys = {
            'en-US': ImgFulltimeLatam,
            'pt-BR': ImgFulltimeBrasil,
            'es-MX': ImgFulltimeMexico,
            'es-AR': ImgFulltimeArgentina,
            'es-CL': ImgFulltimeArgentina
        };
        if (companys[language]) {
            return companys[language];
        }
        return companys['es-US'];
    }

    const positionMarker = language => {
        const positionsMarker = {
            'en-US': [25.878450, -80.365010],
            'es-MX': [19.549260, -99.208970],
            'pt-BR': [-22.215470, -49.653800],
            'es-AR': [-34.613750, -58.521320],
            'es-CL': [-34.613750, -58.521320]
        };
        if(positionsMarker[language]) {
            return positionsMarker[language];
        }
        return positionsMarker['en-US'];
    }

    const alignCenterMap = language => {
        const positionsCenter = {
            'en-US': !sizeMobile ? [25.878496, -80.363274] : [25.878450, -80.365010],
            'es-MX': !sizeMobile ? [19.549319, -99.207382] : [19.549260, -99.208970],
            'pt-BR': !sizeMobile ? [-22.215501, -49.652456] : [-22.215470, -49.653800],
            'es-AR': !sizeMobile ? [-34.613712, -58.519927] : [-34.613762, -58.5213999],
            'es-CL': !sizeMobile ? [-34.613712, -58.519927] : [-34.613762, -58.5213999]
        };
        if (positionsCenter[language]) {
            return positionsCenter[language];
        }
        return positionsCenter['en-US'];
    }

    const onAddListenerClosePopup = e => {
        document.querySelectorAll('.flag').forEach(item =>
            item.addEventListener('mouseover', _ => e.target.closePopup()));
    };

    useEffect(() => {
        const resize = () => setSize(window.innerWidth);
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    return (
        <Map
            zoom={18}
            minZoom={5}
            maxZoom={18}
            id="map_footer"
            zoomSnap={false}
            zoomControl={true}
            maxBoundsViscosity={1}
            scrollWheelZoom={false}
            center={alignCenterMap(localeLanguage)}
        >
            <TileLayer
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"
            />
            <Marker
                onMouseOver={(e) => {
                    e.target.openPopup();
                    onAddListenerClosePopup(e);
                }}
                icon={ImgMarker}
                position={positionMarker(localeLanguage)}
            >
                <Popup>
                    <div className="image__popup">
                        <img
                            width="100%"
                            height={120}
                            alt="Fulltime | Google Maps"
                            style={{ borderRadius: 10 }}
                            src={renderImageCompany(localeLanguage)}
                            onClick={() => ActionsClient.accessLink(localeLanguage, 'streetview')}
                        />
                    </div>
                    <div className="content__popup" onClick={() => ActionsClient.accessLink(localeLanguage, 'streetview')}>
                        <img src={ImgGoogle} width='auto' height={16} alt="Google"/>&nbsp;
                        <strong style={{ color: '#4285f4', fontSize: 14 }}>Street View</strong>&nbsp;
                        <img src={ImgStreetView} width={18} height={18} alt="Street View"/>
                    </div>
                </Popup>
            </Marker>
        </Map>
    );
};

MapFooter.propTypes = {
    localeLanguage: PropTypes.string
};
