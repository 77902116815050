/** @name Images */
import ImgAppPersonalizado from 'images/solutions/personalizado_app.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const LayoutMobile = ({ t }) =>
    <div className="container__background__mobile">
        <section className="card__example__mobile">
            <article className="card__content__mobile">
                <div className="image__mobile">
                    <img 
                        className="img__layout"
                        src={ImgAppPersonalizado} 
                        alt={t('layout_personalizado')}
                    />
                </div>
                <div className="content__card__app__client">
                    <h1>{t('layout_personalizado')}</h1>
                    <h2>({t('aplicativo_sistema_empresa')})</h2>
                    <p>{t('descricao_aplicativo_sistema_empresa_1')}</p>
                    <p>{t('descricao_aplicativo_sistema_empresa_2')}</p>
                    <p>{t('descricao_aplicativo_sistema_empresa_3')}</p>
                </div>
            </article>
        </section>
    </div>;

LayoutMobile.propTypes = {
    t: PropTypes.func
};