/** @name Images */
// English
import AppStoreEn from 'images/solutions/app_store_en.png';
import GooglePlayEn from 'images/solutions/google_play_en.png';
// Portuguese
import AppStorePt from 'images/solutions/app_store_pt.png';
import GooglePlayPt from 'images/solutions/google_play_pt.png';
// Espanhol
import AppStoreEs from 'images/solutions/app_store_es.png';
import GooglePlayEs from 'images/solutions/google_play_es.png';
/** @name Constants */
import {
   APP_STORE,
   WEBSITE_FULLTIME,
   PRODUCTS_FULLTIME,
   GOOGLE_STREET_VIEW
} from 'constants/index';
/**
 * @author Hector Rodrigues Da Silva
 */
export class ActionsClient {
   /**
    *
    * @param language
    */
   static accessLink(language, name) {
      const typesNavigate = {
         appstore: APP_STORE,
         product: PRODUCTS_FULLTIME,
         streetview: GOOGLE_STREET_VIEW,
         websiteCompany: WEBSITE_FULLTIME
      };
      const links = {
         'pt-BR': typesNavigate[name].br,
         'en-US': typesNavigate[name].us,
         'es-MX': typesNavigate[name].mx,
         'es-AR': typesNavigate[name].ar
      };
      const receivedLink = links[language];
      if (receivedLink) {
         return window.open(receivedLink);
      }
      return window.open(links['en-US']);
   }

   /**
    *
    * @param {*} language
    * @param {*} platform
    */
   static downloadApp(language, platform) {
      const imgDownload = {
         'pt_apple': AppStorePt,
         'en_apple': AppStoreEn,
         'es_apple': AppStoreEs,
         'pt_google': GooglePlayPt,
         'en_google': GooglePlayEn,
         'es_google': GooglePlayEs,
      };
      const splitLanguage = language.split('-')[0] ?? language;
      const receivedImgDownload = imgDownload[`${splitLanguage}_${platform}`];
      if (receivedImgDownload) {
         return receivedImgDownload;
      }
      return imgDownload[`en_${platform}`];
   }
}
