/** @name Images */
import ImgCloudEconomy from 'images/subheader/cloud_economy.png';
import ImgCircleArtificialIntelligence from 'images/subheader/circle_artificial_intelligence.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const SubHeader = ({ t, isBrasil, idVideoYouTube }) =>
    <>
        <div data-aos="fade-right" data-aos-duration="1500">
            <div className="header__information">
                <section className="articles__header">
                    <article className="container__header">
                        <span className="title__header__content">{t('aprimorando_sua_seguranca_e_monitoramento')}</span>
                        <span className="title__header__content">{t('com_inteligencia_artificial')}</span>
                        <span className="info__header__content">{t('armazene_visualize_aovivo')}</span>
                    </article>
                    <button
                        type="button"
                        className="btn__more__informations"
                        onClick={() => window.open(isBrasil ? 'https://wa.me/message/C2EY2AQPWZVEH1' : 'https://api.whatsapp.com/message/PGWQITPU2IPYB1')}>
                        <span className="text__btn__more">{t('fale_com_consultor')}</span>
                    </button>
                </section>
                <img
                    width="auto"
                    height="50%"
                    src={ImgCloudEconomy}
                    className="img__cloud__header"
                    alt={t('agora_quem_escolhe_e_voce')}
                />
            </div>
        </div>
        <div data-aos="fade-right" data-aos-duration="1500">
            <div className="container__subheader">
                <div className="content__subheader__video">
                    <div className="container__video__fullcam">
                        <div data-aos="fade-right" data-aos-duration="1500" style={{ width: '100%', height: '100%' }}>
                            <iframe
                                frameBorder="0"
                                id="ytplayer_fullcam"
                                title='fullcam_presentation'
                                className="video__presentation__fullcam"
                                src={`https://www.youtube.com/embed/${idVideoYouTube}?autoplay=0&controls=0&origin=https://fullcam.me`}>
                            </iframe>
                        </div>
                    </div>
                    <img
                        width="100%"
                        height="auto"
                        src={ImgCircleArtificialIntelligence}
                        className="img__intelligence spinner-img"
                        alt="FABI | Fulltime Artificial Business Intelligence"
                    />
                </div>
                <div className="content__subheader__information">
                    <span className="paragraph__header">{t('descricao_fullcam_customizavel')}</span>
                </div>
            </div>
        </div>
    </>

SubHeader.propTypes = {
    t: PropTypes.func,
    isBrasil: PropTypes.bool,
    ID_YOUTUBE: PropTypes.string
};