import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemFlag = ({ localeLanguage, changeIconLanguage, setLocaleLanguage, imgFlag, altCountry, lngFlag, className }) =>
    <li className="flags__language">
        <img
            src={imgFlag}
            width={30}
            height={30}
            onClick={() => {
                changeIconLanguage(lngFlag, className);
                setLocaleLanguage(lngFlag);
            }}
            alt={`Icon ${altCountry}`}
            title={`Fulltime ${altCountry}`} 
            className={`${className} flag ${localeLanguage === lngFlag ? 'selected__flag' : ''}`}
        />
    </li>;

ItemFlag.propTypes = {
    localeLanguage: PropTypes.string,
    changeIconLanguage: PropTypes.func,
    setLocaleLanguage: PropTypes.func,
    flag: PropTypes.string,
    altCountry: PropTypes.string,
    i18next: PropTypes.string,
    className: PropTypes.string
};