/** @name Images */
import ImgRtmp from 'images/rtmp/rtmp.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ProtocolRTMP = ({ t }) =>
    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <div className="info__protocol__rtmp">
            <h1 className="info__protocol__rtmp__title">
                {t('fullcam_utiliza_rtmp')}
            </h1>
            <p style={{ marginBottom: 50 }} className="info__protocol__rtmp__content">
                {t('descricao_fullcam_utiliza_rtmp_1')}
            </p>
            <img 
                width="70%" 
                height="auto" 
                src={ImgRtmp} 
                className="info__procotol__img" 
                alt={t('fullcam_utiliza_rtmp')} 
            />
            <p className="info__protocol__rtmp__content">
                {t('descricao_fullcam_utiliza_rtmp_2')}
            </p>
        </div>
    </div>;

ProtocolRTMP.propTypes = {
    t: PropTypes.func
};