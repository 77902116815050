import './index.css';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

export class Alert {
    
    constructor() {
        toastr.options = {
            closeButton: true,
            debug: false,
            newestOnTop: false,
            progressBar: false,
            positionClass: "toast-bottom-right",
            preventDuplicates: false,
            onclick: null,
            showDuration: 300,
            hideDuration: 1000,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            messageClass: 'message-toastr-default'
        };
    }

    success(text) {
        toastr.success(text);
    }

    error(text) {
        toastr.error(text);
    }

    warning(text) {
        toastr.warning(text);   
    }

    info(text) {
        toastr.info(text);
    }

    clear() {
        toastr.clear();
    }
}