/** @name Images */
import ImgWhatsApp from 'images/knowmore/whatsapp.png';
import ImgCallCenter from 'images/knowmore/call_center.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const CallCenter = ({ t, isBrasil }) =>
    <>
        <section className="know__more__call__center">
            <article className="content__know__more">
                <h1>{t('quer_saber_mais_fale_com_consultores')}</h1>
                <button 
                    type="button" 
                    className="button__meet__all"
                    onClick={() => window.open(isBrasil ? 'https://chat.whatsapp.com/DY9TJRBqHAT81tBt4E2xic' : 'https://chat.whatsapp.com/Hv6FZchhaBwHfbTA3VTo95')} 
                >
                    <img 
                        width="auto" 
                        height="100%" 
                        alt="WhatsApp" 
                        src={ImgWhatsApp} 
                    />
                    &nbsp;{t('clique_aqui')}
                </button>
            </article>
            <div className="ilustration__know__more">
                <img 
                    width={700} 
                    height="auto" 
                    src={ImgCallCenter} 
                    alt={t('quer_saber_mais_fale_com_consultores')} 
                />
            </div>
        </section>
    </>;

CallCenter.propTypes = {
    t: PropTypes.func,
    isBrasil: PropTypes.bool
};