export const API = {
    CONTACT: "https://web.fullcam.me/api/v1/landing/form"
};
export const APP_STORE = {
    br: 'https://apps.apple.com/br/app/fullcam/id1454231929',
    us: 'https://apps.apple.com/us/app/fullcam/id1454231929',
    mx: 'https://apps.apple.com/es/app/fullcam/id1454231929',
    ar: 'https://apps.apple.com/es/app/fullcam/id1454231929'
};
export const PRODUCTS_FULLTIME = {
    br: 'https://fulltime.com.br/solucoes/fullcam/',
    us: 'https://fulltimelatam.com/nuestras-soluciones/Fullcam',
    mx: 'https://fulltime.mx/soluciones/fullcam/',
    ar: 'https://www.fulltime.com.ar/soluciones/fullcam/'
};
export const GOOGLE_STREET_VIEW = {
    mx: 'https://www.google.com/maps/@19.5484272,-99.1940636,3a,75y,72.6h,91.27t/data=!3m6!1e1!3m4!1sVClJTxbi9sQwS9fHXkDNrQ!2e0!7i16384!8i8192',
    br: 'https://www.google.com.br/maps/@-22.2155886,-49.65375,3a,75y,269.41h,91.19t/data=!3m6!1e1!3m4!1sJey4K0d7S5mTgbpDwwmgAw!2e0!7i16384!8i8192',
    ar: 'https://www.google.com/maps/uv?pb=!1s0x95bcb7d9c0008e55%3A0x91f0d6b1beed8563!3m1!7e115!4s%2Fmaps%2Fplace%2Ffulltime%2Bargentina%2F%40-34.614062%2C-58.5214992%2C3a%2C75y%2C318.88h%2C90t%2Fdata%3D*213m4*211e1*213m2*211sBRd6iB5Odu4F5aaS0_SWPQ*212e0*214m2*213m1*211s0x95bcb7d9c0008e55%3A0x91f0d6b1beed8563%3Fsa%3DX!5sfulltime%20argentina%20-%20Pesquisa%20Google!15sCgIgAQ&imagekey=!1e2!2sBRd6iB5Odu4F5aaS0_SWPQ&hl=pt-BR&sa=X&ved=2ahUKEwiN8rujuO7rAhVgK7kGHV9wDHQQpx8wCnoECBYQBg',
    us: 'https://www.google.com/maps/place/11801+NW+100th+Rd+%2311,+Medley,+FL+33178,+EUA/@25.878349,-80.3648019,3a,75y,217.65h,86.71t/data=!3m8!1e1!3m6!1sBjPjV9B6IxSOH0Hz20hf0A!2e0!3e11!6s%2F%2Fgeo2.ggpht.com%2Fmaps%2Fphotothumb%2Ffd%2Fv1%3Fbpb%3DChEKD3NlYXJjaC5nd3MtcHJvZBJJCjsJiwribWK72YgR5TLoy6pbzuQaJwsQ04W4QhoeGhwKFgoUChIJiwribWK72YgRztKNVyK8rEsSAjExDCoKDQAAAAAVAAAAABoFCHgQ6AI%26gl%3DBR!7i16384!8i8192!4m5!3m4!1s0x88d9bb626de20a8b:0xe4ce5baacbe832e5!8m2!3d25.8779547!4d-80.365082'
};
export const WEBSITE_FULLTIME = {
    mx: 'https://fulltime.mx',
    br: 'https://fulltime.com.br',
    us: 'https://fulltimelatam.com',
    ar: 'https://www.fulltime.com.ar'
};