/** @name Images */
import ImgAppMobile from 'images/solutions/fullcam_mobile.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name External */
import { ActionsClient } from 'services/actions';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const FullCamMobile = ({ t, localeLanguage }) =>
    <div className="article__informations__fullcam">
        <div className="content__box__img__fullcam__mobile">
            <img
                width="auto"
                height={450}
                src={ImgAppMobile}
                alt="FullCam Mobile"
            />
        </div>
        <h1>{t('aplicativo_fullcam')}</h1>
        <center>
            <div className="box__application__fullcam">
                <div className="align__informations__box__fullcam">
                    <p className="text__information__application">
                        <span style={{ color: '#ee1b26' }}>+</span>&nbsp;<strong>30 {t('mil')}</strong>&nbsp;{t('cameras_cadastradas')}
                    </p>
                    <p className="text__information__application">
                        <span style={{ color: '#ee1b26' }}>+</span>&nbsp;<strong>35 {t('mil')}</strong>&nbsp;{t('usuarios')}
                    </p>
                    <p className="text__information__application">
                        <span style={{ color: '#ee1b26' }}>+</span>&nbsp;<strong>2 {t('milhoes')}</strong>&nbsp;{t('pre_alarme')}
                    </p>
                    <div className="row__items__download__app">
                        <div>
                            <img
                                width="auto"
                                style={{ marginRight: 35 }}
                                className="button__download"
                                height={90} alt="Google Play FullCam"
                                src={ActionsClient.downloadApp(localeLanguage, 'google')}
                                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fullcam')}
                            />
                            <img
                                height={90}
                                width="auto"
                                alt="App Store FullCam"
                                className="button__download"
                                onClick={() => ActionsClient.accessLink(localeLanguage, 'appstore')}
                                src={ActionsClient.downloadApp(localeLanguage, 'apple')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </center>
    </div>;

FullCamMobile.propTypes = {
    t: PropTypes.func,
    localeLanguage: PropTypes.string
};
