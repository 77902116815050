/** @name Images */
import ImgLogoFullCamWhite from 'images/footer/logo_fullcam_white.svg';
import ImgLogoFulltime from 'images/footer/fulltime_logo.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
/** @name External */
import { ActionsClient } from "services/actions";
/** @name Internal */
import { MapFooter } from './map';
import { ItemAddress } from './itemAddress';
import { ItemContact } from './ItemContact';

/** @name Constants */
const address = Object.freeze({
    'en-US': {
        cep: 'Medley - Flórida, 33178',
        address: '11801 N.W. 100th Road, Suite #11'
    },
    'pt-BR': {
        cep: 'CEP 17404-318',
        address: 'Av. Presidente Vargas, 70 - Centro. Garça/SP'
    },
    'es-MX': {
        cep: 'Estado de Mexico C.P 54030',
        address: 'ES Atenco 2, Col. La Romana Tlalnepantla de Baz,'
    },
    'es-AR': {
        cep: 'info@fulltime.com.ar',
        address: 'José Pedro Varela 5181 Villa Devoto (C.P. 1419) C.A.B.A.'
    },
    'es-CL': {
        cep: 'info@fulltime.com.ar',
        address: 'AR - José Pedro Varela 5181 Villa Devoto (C.P. 1419) C.A.B.A.'
    }
});

const contact = Object.freeze({
    'pt-BR': {
        firstHref: 'tel:+551434078800',
        firstContact: '+55 14 3407 8800',
        secondaryHref: 'tel:+551150698800',
        secondaryContact: '+55 11 5069 8800'
    },
    'es-MX': {
        firstHref: 'tel:+525571609782',
        firstContact: '+52 55 7160 9782',
        secondaryHref: 'tel:+525571609476',
        secondaryContact: '+52 55 7160 9476'
    },
    'es-AR': {
        firstHref: 'tel:(+5411)53528450',
        firstContact: '(+5411) 53528450',
        secondaryContact: 'info@fulltime.com.ar',
        secondaryHref: 'mailto:info@fulltime.com.ar',
    },
    'es-CL': {
        firstHref: 'tel:(+5411)53528450',
        firstContact: '(+5411) 53528450',
        secondaryContact: 'info@fulltime.com.ar',
        secondaryHref: 'mailto:info@fulltime.com.ar',
    },
    'en-US': {
        firstHref: 'tel:+17862923131',
        firstContact: '+1 786 292 3131',
        secondaryContact: 'contacto@fulltimelatam.com',
        secondaryHref: 'mailto:contacto@fulltimelatam.com',
    }
});

/**
 * @author Hector Rodrigues Da Silva
 */
export const Footer = ({ t, localeLanguage }) => {

    const idVideoYouTube = 'TWno9yl4rso';

    const addressCountry = useCallback(language => {
        if (address[language]) {
            return address[language];
        }
        return address['en-US'];
    }, []);

    const contactCountry = useCallback(language => {
        if (contact[language]) {
            return contact[language];
        }
        return contact['en-US'];
    }, []);

    return (
        <div className="container__footer">
            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                <MapFooter
                    localeLanguage={localeLanguage}
                />
                <footer>
                    <div className="content__footer">
                        <div className="container__video__fullcam">
                            <iframe
                                frameBorder="0"
                                title='exposec2023'
                                id="ytplayer_exposec"
                                className="video__presentation__fullcam"
                                src={`https://www.youtube.com/embed/${idVideoYouTube}?autoplay=0&origin=https://fullcam.me`}>
                            </iframe>
                        </div>
                        <div className="logo__example__footer">
                            <img
                                alt="FullCam"
                                width="240px"
                                height="auto"
                                src={ImgLogoFullCamWhite}
                                className="logo__fullcam__footer"
                                onClick={() => ActionsClient.accessLink(localeLanguage, 'product')}
                            />
                        </div>
                        <div className="logo__example__footer">
                            <img
                                width="250px"
                                height="auto"
                                alt="Fulltime"
                                src={ImgLogoFulltime}
                                className="logo__fulltime__footer"
                                onClick={() => ActionsClient.accessLink(localeLanguage, 'websiteCompany')}
                            />
                        </div>
                        <div className="info__fulltime__footer">
                            <ItemAddress
                                cep={addressCountry(localeLanguage).cep}
                                address={addressCountry(localeLanguage).address}
                            />
                        </div>
                        <div className="info__fulltime__footer">
                            <ul>
                                <ItemContact
                                    firstHref={contactCountry(localeLanguage).firstHref}
                                    firstContact={contactCountry(localeLanguage).firstContact}
                                    secondaryHref={contactCountry(localeLanguage).secondaryHref}
                                    secondaryContact={contactCountry(localeLanguage).secondaryContact}
                                />
                            </ul>
                        </div>
                        <div style={{ width: '100%' }}>
                            <img
                                height={40}
                                width="auto"
                                alt="Google Play FullCam"
                                style={{ marginRight: 10 }}
                                className="app__fullcam__footer"
                                src={ActionsClient.downloadApp(localeLanguage, 'google')}
                                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fullcam')}
                            />
                            <img
                                height={40}
                                width="auto"
                                alt="App Store FullCam"
                                className="app__fullcam__footer"
                                onClick={() => ActionsClient.accessLink(localeLanguage, 'appstore')}
                                src={ActionsClient.downloadApp(localeLanguage, 'apple')}
                            />
                        </div>
                        <span className="copyright__fulltime">© {new Date().getFullYear()} - {t('copyright')}</span>
                    </div>
                </footer>
            </div>
        </div>
    );
}

Footer.propTypes = {
    t: PropTypes.func,
    localeLanguage: PropTypes.string,
};