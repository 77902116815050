/** @name Images */
import ImgPreAlarme from 'images/benefits/pre_alarme.png';
import ImgBairroSeguro from 'images/benefits/bairro_seguro.png';
import ImgDeteccaoPessoa from 'images/benefits/deteccao_de_pessoa.png';
import ImgAnaliseImagens from 'images/benefits/analise_de_imagens.png';
import ImgTimelineMovimento from 'images/benefits/timeline_movement.png';
import ImgDeteccaoMovimento from 'images/benefits/deteccao_de_movimento.png';
import ImgAssistaQuandoQuiser from 'images/benefits/assista_quando_quiser.png';
import ImgSelecioneSuasCameras from 'images/benefits/selecione_suas_cameras.png';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/** @name External */
import { Render } from 'app/helpers';
/** @name Internal */
import { DotCarousel } from './dots';
import { ItemCarousel } from './itemCarousel';
/** @name Constants */
const dots = Array.from({ length: 8 }, (_, i) => i + 1);
/**
 * @author Hector Rodrigues Da Silva
 */
export const Carousel = ({ t }) => {

    const [dotSelected, setPoint] = useState(1);
    const [carouselCurrent, setCarousel] = useState(1);
    const [animateCarousel, setAnimateCarousel] = useState('fade-right');

    const _prev = useCallback(() => {
        setAnimateCarousel('zoom-out');
        if (carouselCurrent > 1) {
            setPoint(dotSelected - 1);
            setCarousel(carouselCurrent - 1);
        } else {
            setCarousel(8);
            setPoint(8);
        }
    }, [carouselCurrent, dotSelected])

    const selectDotItem = selectItem => {
        if (selectItem > carouselCurrent) {
            setAnimateCarousel('fade-right');
        } else {
            setAnimateCarousel('zoom-out');
        }
        if (selectItem !== carouselCurrent) {
            setPoint(selectItem);
            setCarousel(selectItem);
        }
    }

    const _next = useCallback(() => {
        setAnimateCarousel('fade-right');
        if (carouselCurrent < 8) {
            setPoint(dotSelected + 1);
            setCarousel(carouselCurrent + 1);
        } else {
            setCarousel(1);
            setPoint(1);
        }
    }, [carouselCurrent, dotSelected])

    const visibleItem = (item) => {
        return carouselCurrent === item;
    }

    useEffect(() => {
        const intervalNextCarousel = setTimeout(() => {
            _next();
        }, 7000);
        return () => clearTimeout(intervalNextCarousel);
    }, [_next, carouselCurrent, dotSelected])

    const items = [
        {
            target: 1,
            img: ImgBairroSeguro,
            title: t('bairro_seguro'),
            content: t('descricao_bairro_seguro')
        }, {
            target: 2,
            img: ImgSelecioneSuasCameras,
            title: t('selecione_suas_cameras'),
            content: t('descricao_selecione_suas_cameras')
        }, {
            target: 3,
            img: ImgAssistaQuandoQuiser,
            title: t('assista_quando_quiser'),
            content: t('descricao_assista_quando_quiser')
        }, {
            target: 4,
            img: ImgPreAlarme,
            title: t('pre_alarme'),
            content: t('descricao_pre_alarme')
        }, {
            target: 5,
            img: ImgTimelineMovimento,
            title: t('timeline_movimento'),
            content: t('descricao_timeline_movimento')
        }, {
            target: 6,
            img: ImgDeteccaoPessoa,
            title: t('deteccao_de_pessoa'),
            content: t('descricao_deteccao_de_pessoa')
        }, {
            target: 7,
            img: ImgDeteccaoMovimento,
            title: t('deteccao_de_movimento'),
            content: t('descricao_deteccao_de_movimento')
        }, {
            target: 8,
            img: ImgAnaliseImagens,
            title: t('analise_de_imagens'),
            content: t('descricao_analise_de_imagens')
        }
    ];

    return (
        <section className="slider">
            <div className="flexslider">
                <span className="action__carousel" onClick={() => _prev()}>
                    <FontAwesomeIcon icon="angle-left" />
                </span>
                <ul className="slides">
                    {items.map((item, i) =>
                        <Render key={i} has={visibleItem(item.target)}>
                            <ItemCarousel
                                img={item.img}
                                title={item.title}
                                content={item.content}
                                typeAnimate={animateCarousel}
                            />
                        </Render>
                    )}
                </ul>
                <span className="action__carousel" onClick={() => _next()}>
                    <FontAwesomeIcon icon="angle-right" />
                </span>
            </div>
            <div className="selected__items__carousel">
                <ol className="list__items__carousel">
                    {dots.map(n =>
                        <DotCarousel
                            key={n}
                            numberDot={n}
                            selectDotItem={selectDotItem}
                            dotCurrentCarousel={dotSelected}
                        />
                    )}
                </ol>
            </div>
        </section>
    );
};

Carousel.propTypes = {
    t: PropTypes.func
};