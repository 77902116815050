/** @name Dependencies */
import axios from "axios";
import qs from "qs";
/** @name Constants */
import { API } from "constants/index";

export class StorageApp {

    /**
     *
     * @param data
     */
    static prepareSendForm(data) {
        data = {
            ...data,
            telefone: `${data.ddi} ${data.telefone}`
        }
        delete data.ddi;
        return data;
    }

    /**
     *
     * @param data
     * @returns {Promise<unknown>}
     */
    static sendFormContact(data) {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: API.CONTACT,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: qs.stringify(data)
            })
                .then(() => resolve())
                .catch(() => reject()))
    }
}