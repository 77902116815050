import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const DotCarousel = ({ dotCurrentCarousel, numberDot, selectDotItem }) =>
    <li 
        onClick={() => selectDotItem(numberDot)} 
        className={`dot__item dot-${numberDot} ${dotCurrentCarousel === numberDot && 'selected__item__carousel'}`}
    />;

DotCarousel.propTypes = {
    dotCurrentCarousel: PropTypes.number,
    numberDot: PropTypes.number,
    selectDotItem: PropTypes.func
};
