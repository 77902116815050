/** @name Images */
import ImgDominioNegocio from 'images/solutions/dominio_negocio.png';
import ImgPagamentoRecorrente from 'images/solutions/pagamento_recorrente.png';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/** @name Internal */
import { LayoutMobile } from './layouMobile';
import { ItemSolution } from './itemSolutions';
import { FullCamMobile } from './fullcamMobile';
/**
 * @author Hector Rodrigues Da Silva
 */
export const Solutions = ({ t, localeLanguage }) =>
    <>
        <div data-aos="fade-up" data-aos-duration="1500">
            <h1 className="title__ilustrations">{t('o_que_voce_ganha')}</h1>
            <section className="ilustrations__solutions">
                <ItemSolution img={ImgPagamentoRecorrente} title={t('receita_recorrente')}>
                    {t('descricao_receita_recorrente')}
                </ItemSolution>
                <ItemSolution img={ImgDominioNegocio} title={t('dominio_seu_negocio')}>
                    {t('descricao_dominio_seu_negocio')}
                </ItemSolution>
            </section>
        </div>
        <div data-aos="fade-right" data-aos-duration="1500">
            <LayoutMobile
                t={t} />
        </div>
        <div data-aos="fade-down" data-aos-duration="1500">
            <FullCamMobile
                t={t}
                localeLanguage={localeLanguage}
            />
        </div>
    </>;

Solutions.propTypes = {
    t: PropTypes.func,
    localeLanguage: PropTypes.string
};