import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemAddress = ({ address, cep }) =>
    <>
        <h2>{address}</h2>
        <h3>{cep}</h3>
    </>;

ItemAddress.propTypes = {
    address: PropTypes.string,
    cep: PropTypes.string
};