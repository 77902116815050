import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemContact = ({ firstHref, secondaryHref, firstContact, secondaryContact }) =>
    <>
        <a href={firstHref}><li>{firstContact}</li></a>
        <a href={secondaryHref}><li>{secondaryContact}</li></a>
    </>;

ItemContact.ṕropTypes = {
    firstHref: PropTypes.string,
    secondaryHref: PropTypes.string,
    firstContact: PropTypes.string,
    secondaryContact: PropTypes.string
};