/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React from 'react';
/** @name Internal */
import { Carousel } from './carousel';
/**
 * @author Hector Rodrigues Da Silva
 */
export const Benefits = ({ t }) =>
    <div className="benefits__information">
        <h1>{t('titulo_vantagens')}</h1>
        <div data-aos="fade-up" data-aos-duration="2500">
            <Carousel t={t} />
        </div>
    </div>;

Benefits.propTypes = {
    t: PropTypes.func
};