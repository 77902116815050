/** @name CSS */
import './index.css';
/** @name Dependencies */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ScrollUp = () => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (!showScroll && window.pageYOffset > 400) {
                setShowScroll(true)
            } else if (showScroll && window.pageYOffset <= 400) {
                setShowScroll(false)
            }
        });
    }, [showScroll]);

    return (
        <button
            type="button"
            className="scroll__button__up"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{ display: showScroll ? 'block' : 'none' }}>
            <FontAwesomeIcon icon="angle-up" />
        </button>
    );
};
