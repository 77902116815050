import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemCarousel = ({ img, typeAnimate, title, content }) =>
    <li className="container__item__carousel">
        <div className="item__carousel" data-aos={typeAnimate} data-aos-duration="1500">
            <img src={img} className="img__carousel" width={300} height="auto" alt="Carousel Ilustration" />
            <div className="container__content__general">
                <h1 className="title__item__carousel">{title}</h1>
                <p className="content__item__carousel">
                    {content}
                </p>
            </div>
        </div>
    </li>;

ItemCarousel.propTypes = {
    img: PropTypes.string,
    typeAnimate: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string
};
