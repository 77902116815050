/** @name Images */
import ImgFlagUsa from 'images/header/usa.png';
import ImgFlagMx from 'images/header/mexico.png';
import ImgFlagBrazil from 'images/header/brazil.png';
import ImgFlagArgentina from 'images/header/argentina.png';
import ImgLogoFullCam from 'images/header/logo_fullcam_nova.svg';
/** @name CSS */
import './index.css';
import './index.m.css';
/** @name Dependencies */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import i18next from 'i18next';
/** @name Internal */
import { ItemFlag } from './itemflag';
/**
 * @author Hector Rodrigues Da Silva
 */
export const NavBar = memo(({ t, setLocaleLanguage, localeLanguage, isBrasil }) => {

    const changeIconLanguage = (language, className) => {
        i18next.changeLanguage(language);
        document.querySelector(`.${className}`).classList.toggle('selected__flag', language);
    };

    return (
        <header className="header__app">
            <div className="logo__lp">
                <img
                    height={50}
                    width="auto"
                    src={ImgLogoFullCam}
                    alt="Logo FullCam"
                    onClick={() => window.open('https://cloud.fullcam.me')}
                />
            </div>
            <nav>
                <ul>
                    <li>
                        <button
                            type="button"
                            className="btn__nav__contract"
                            onClick={() => window.open(
                                isBrasil
                                    ? 'https://api.whatsapp.com/message/C2EY2AQPWZVEH1'
                                    : 'https://api.whatsapp.com/message/PGWQITPU2IPYB1'
                            )}
                        >
                            {t('contrate_fullcam')}
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="btn__nav__area__client"
                            onClick={() => window.location.href = 'https://cloud.fullcam.me'}>
                            {t('area_cliente')}
                        </button>
                    </li>
                    <div className="options__language">
                        <ItemFlag
                            imgFlag={ImgFlagBrazil}
                            lngFlag={'pt-BR'}
                            altCountry={'Brasil'}
                            className={'flag__brazil'}
                            localeLanguage={localeLanguage}
                            setLocaleLanguage={language => setLocaleLanguage(language)}
                            changeIconLanguage={(language, className) => changeIconLanguage(language, className)}
                        />
                        <ItemFlag
                            imgFlag={ImgFlagUsa}
                            lngFlag={'en-US'}
                            altCountry={'Latam'}
                            localeLanguage={localeLanguage}
                            className={'flag__unitedstates'}
                            setLocaleLanguage={language => setLocaleLanguage(language)}
                            changeIconLanguage={(language, className) => changeIconLanguage(language, className)}
                        />
                        <ItemFlag
                            imgFlag={ImgFlagArgentina}
                            lngFlag={'es-AR'}
                            altCountry={'Argentina'}
                            className={'flag__argentina'}
                            localeLanguage={localeLanguage}
                            setLocaleLanguage={language => setLocaleLanguage(language)}
                            changeIconLanguage={(language, className) => changeIconLanguage(language, className)}
                        />
                        <ItemFlag
                            imgFlag={ImgFlagMx}
                            lngFlag={'es-MX'}
                            altCountry={'México'}
                            className={'flag__mexico'}
                            localeLanguage={localeLanguage}
                            setLocaleLanguage={language => setLocaleLanguage(language)}
                            changeIconLanguage={(language, className) => changeIconLanguage(language, className)}
                        />
                    </div>
                </ul>
            </nav>
        </header>
    );
});

NavBar.propTypes = {
    t: PropTypes.func,
    setLocaleLanguage: PropTypes.func,
    localeLanguage: PropTypes.string,
    isBrasil: PropTypes.bool
};