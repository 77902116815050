import PropTypes from 'prop-types';
import React from 'react';
/**
 * @author Hector Rodrigues Da Silva
 */
export const ItemFuncionalities = ({ iconImg, text }) =>
    <li>
        <span className="icon__list__options__fullcam">
            <img src={iconImg} width="50%" height="auto" alt={text} />
        </span>
        <span className="text__list__options__fullcam">
            {text}
        </span>
    </li>;

ItemFuncionalities.propTypes = {
    iconImg: PropTypes.string,
    text: PropTypes.string
};