/** @name CSS */
import './index.css';
import 'aos/dist/aos.css';
/** @name Dependencies */
import Aos from 'aos';
import React from 'react';
import { withNamespaces } from 'react-i18next';
/** @name External */
import { Alert } from 'utils';
/** @name Internal */
import { StorageApp } from './storage';
import {
    NavBar,
    Footer,
    ScrollUp,
    KnowMore,
    Benefits,
    Solutions,
    SubHeader,
    ProtocolRTMP,
    ToolsFullCam
} from 'app/components';
/**
 * @author Hector Rodrigues Da Silva
 */
class App extends React.PureComponent {

    static YT_ID_VIDEO_BRAZIL = '7mRjwcpcARU';
    static YT_ID_VIDEO_SPANISH = 'J0TJVcK0auY';

    static DDI_COUNTRY = {
        'pt': { ddi: '', country: 'Brasil' },
        'es': { ddi: '', country: 'Não identificado' },
        'en': { ddi: '+1', country: 'Estados Unidos' },
        'es-CL': { ddi: '+56', country: 'Chile' },
        'pt-BR': { ddi: '+55', country: 'Brasil' },
        'es-MX': { ddi: '+52', country: 'México' },
        'es-AR': { ddi: '+54', country: 'Argentina' },
        'en-US': { ddi: '+1', country: 'Estados Unidos' }
    };

    static INITIAL_CONTACT = {
        ddi: '',
        pais: '',
        nome: '',
        email: '',
        empresa: '',
        cpf_cnpj: '',
        telefone: '',
        solucao: 'FullCam',
        area_atuacao: 'Monitoramento'
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            localeLanguage: 'en-US',
            contact: { ...App.INITIAL_CONTACT },
            id_youtube: App.YT_ID_VIDEO_BRAZIL
        };
        Aos.init();
        this.t = props.t;
        this._regexCNPJ = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\/-\d{2}$/);
        this._toastr = new Alert();
        // Methods of Class
        this._bindFunctions();
    }

    /**
     *
     * @private
     */
    _bindFunctions() {
        this._setDDI = this._setDDI.bind(this);
        this._handle = this._handle.bind(this);
        this._handle = this._handle.bind(this);
        this._setLanguage = this._setLanguage.bind(this);
        this._submitContact = this._submitContact.bind(this);
        this._onChangeInputForm = this._onChangeInputForm.bind(this);
        this._onChangeCNPJ_Brasil = this._onChangeCNPJ_Brasil.bind(this);
    }

    _isBrasil() {
        const { localeLanguage } = this.state;
        return localeLanguage === 'pt-BR' || localeLanguage === 'pt';
    }

    /**
     * Handle default to onChange
     *
     * @private
     */
    _handle(key, value, callback) {
        this.setState(state => ({ contact: { ...state.contact, [key]: value } }), callback);
    }

    /**
     *
     * @param {*} value
     * @returns
     */
    _maskCNPJ(value) {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }

    /**
     *
     * @param event
     */
    _onChangeInputForm({ target: { id, value } }) {
        this._handle(id, value);
    }

    /**
     *
     * @param event
     */
    _onChangeCNPJ_Brasil({ target: { id, value } }) {
        if (value.length <= 18) {
            this._handle(id, this._maskCNPJ(value));
        }
    }

    /**
     *
     * @param language
     * @private
     */
    _changeVideoYouTube(language) {
        const { YT_ID_VIDEO_SPANISH, YT_ID_VIDEO_BRAZIL } = App;
        this.setState({ id_youtube: language !== 'pt-BR' && language !== 'en-US' ? YT_ID_VIDEO_SPANISH : YT_ID_VIDEO_BRAZIL });
    }

    /**
     *
     * @param {*} language
     */
    _setLanguage(language) {
        document.title = this.t('title_page');
        document.querySelector('meta[name="description"]').setAttribute("content", this.t('description_page'));
        this._changeVideoYouTube(language);
        this.setState({ localeLanguage: language, contact: App.INITIAL_CONTACT }, () => this._setDDI(language));
    }

    /**
     *
     * @param language
     * @private
     */
    _setDDI(language) {
        const { ddi, country } = App.DDI_COUNTRY[language] ?? App.DDI_COUNTRY['en-US'];
        this._handle('ddi', !ddi ? '+1' : ddi);
        this._handle('pais', !country ? 'Not identified' : country);
    }

    /**
     *
     *  @private
     */
    _submitContact() {
        const { nome, email, empresa, cpf_cnpj, telefone } = this.state.contact;
        if (nome && email && empresa && cpf_cnpj && telefone) {
            if (this._isBrasil() && !this._regexCNPJ.test(cpf_cnpj)) {
                return this._toastr.warning(this.t('cnpj_invalido'));
            }
            this.setState({ isLoad: true });
            const contact = StorageApp.prepareSendForm(this.state.contact)
            StorageApp.sendFormContact(contact)
                .then(() => this._toastr.success(this.t('dados_enviados_sucesso')))
                .catch(() => this._toastr.error(this.t('dados_nao_enviados_erro')))
                .finally(() => this.setState({ isLoad: false, contact: App.INITIAL_CONTACT }))
        } else {
            this._toastr.clear();
            this._toastr.warning(this.t('preencha_todos_dados'));
        }
    }

    componentDidMount() {
        this._setLanguage(this.props.lng);
    }

    render() {
        const isBrasil = this._isBrasil();
        return (
            <>
                <NavBar
                    t={this.t}
                    isBrasil={isBrasil}
                    setLocaleLanguage={this._setLanguage}
                    localeLanguage={this.state.localeLanguage}
                />
                <SubHeader
                    t={this.t}
                    isBrasil={isBrasil}
                    idVideoYouTube={this.state.id_youtube}
                />
                <Solutions
                    t={this.t}
                    localeLanguage={this.state.localeLanguage}
                />
                <Benefits t={this.t} />
                <ProtocolRTMP t={this.t} />
                <ToolsFullCam t={this.t} />
                <KnowMore
                    t={this.t}
                    isBrasil={isBrasil}
                    stateIsLoad={this.state.isLoad}
                    stateContact={this.state.contact}
                    submitContact={this._submitContact}
                    onChangeInputForm={this._onChangeInputForm}
                    onChangeCNPJ_Brasil={this._onChangeCNPJ_Brasil}
                />
                <Footer
                    t={this.t}
                    localeLanguage={this.state.localeLanguage}
                />
                <ScrollUp />
            </>
        )
    }
}

export default withNamespaces('common')(App);
